import { default as about3ROPYmDmvsMeta } from "/home/les/blob/pages/about.vue?macro=true";
import { default as adminiRr3ZgMGKDMeta } from "/home/les/blob/pages/admin.vue?macro=true";
import { default as _91id_93ZNqvLrbpXtMeta } from "/home/les/blob/pages/b/[id].vue?macro=true";
import { default as blobs2naEvhYQKHMeta } from "/home/les/blob/pages/blobs.vue?macro=true";
import { default as indexmVIXcgUp47Meta } from "/home/les/blob/pages/index.vue?macro=true";
import { default as _91id_939uUr3eZ5d1Meta } from "/home/les/blob/pages/p/[id].vue?macro=true";
import { default as _91id_93OaiGCldauGMeta } from "/home/les/blob/pages/s/[id].vue?macro=true";
import { default as search0E036F3eAJMeta } from "/home/les/blob/pages/search.vue?macro=true";
import { default as shareaIrD00LQOEMeta } from "/home/les/blob/pages/share.vue?macro=true";
import { default as signinL6e64VGCuOMeta } from "/home/les/blob/pages/signin.vue?macro=true";
import { default as sourcex6sCxwFnjZMeta } from "/home/les/blob/pages/source.vue?macro=true";
import { default as _91id_93DzLnahi4mtMeta } from "/home/les/blob/pages/tag/[id].vue?macro=true";
import { default as tagsoHMlEsLeToMeta } from "/home/les/blob/pages/tags.vue?macro=true";
import { default as widgeteGIsjqkrWlMeta } from "/home/les/blob/pages/widget.vue?macro=true";
export default [
  {
    name: about3ROPYmDmvsMeta?.name ?? "about",
    path: about3ROPYmDmvsMeta?.path ?? "/about",
    meta: about3ROPYmDmvsMeta || {},
    alias: about3ROPYmDmvsMeta?.alias || [],
    redirect: about3ROPYmDmvsMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/about.vue").then(m => m.default || m)
  },
  {
    name: adminiRr3ZgMGKDMeta?.name ?? "admin",
    path: adminiRr3ZgMGKDMeta?.path ?? "/admin",
    meta: adminiRr3ZgMGKDMeta || {},
    alias: adminiRr3ZgMGKDMeta?.alias || [],
    redirect: adminiRr3ZgMGKDMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZNqvLrbpXtMeta?.name ?? "b-id",
    path: _91id_93ZNqvLrbpXtMeta?.path ?? "/b/:id()",
    meta: _91id_93ZNqvLrbpXtMeta || {},
    alias: _91id_93ZNqvLrbpXtMeta?.alias || [],
    redirect: _91id_93ZNqvLrbpXtMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/b/[id].vue").then(m => m.default || m)
  },
  {
    name: blobs2naEvhYQKHMeta?.name ?? "blobs",
    path: blobs2naEvhYQKHMeta?.path ?? "/blobs",
    meta: blobs2naEvhYQKHMeta || {},
    alias: blobs2naEvhYQKHMeta?.alias || [],
    redirect: blobs2naEvhYQKHMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/blobs.vue").then(m => m.default || m)
  },
  {
    name: indexmVIXcgUp47Meta?.name ?? "index",
    path: indexmVIXcgUp47Meta?.path ?? "/",
    meta: indexmVIXcgUp47Meta || {},
    alias: indexmVIXcgUp47Meta?.alias || [],
    redirect: indexmVIXcgUp47Meta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939uUr3eZ5d1Meta?.name ?? "p-id",
    path: _91id_939uUr3eZ5d1Meta?.path ?? "/p/:id()",
    meta: _91id_939uUr3eZ5d1Meta || {},
    alias: _91id_939uUr3eZ5d1Meta?.alias || [],
    redirect: _91id_939uUr3eZ5d1Meta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OaiGCldauGMeta?.name ?? "s-id",
    path: _91id_93OaiGCldauGMeta?.path ?? "/s/:id()",
    meta: _91id_93OaiGCldauGMeta || {},
    alias: _91id_93OaiGCldauGMeta?.alias || [],
    redirect: _91id_93OaiGCldauGMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: search0E036F3eAJMeta?.name ?? "search",
    path: search0E036F3eAJMeta?.path ?? "/search",
    meta: search0E036F3eAJMeta || {},
    alias: search0E036F3eAJMeta?.alias || [],
    redirect: search0E036F3eAJMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/search.vue").then(m => m.default || m)
  },
  {
    name: shareaIrD00LQOEMeta?.name ?? "share",
    path: shareaIrD00LQOEMeta?.path ?? "/share",
    meta: shareaIrD00LQOEMeta || {},
    alias: shareaIrD00LQOEMeta?.alias || [],
    redirect: shareaIrD00LQOEMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/share.vue").then(m => m.default || m)
  },
  {
    name: signinL6e64VGCuOMeta?.name ?? "signin",
    path: signinL6e64VGCuOMeta?.path ?? "/signin",
    meta: signinL6e64VGCuOMeta || {},
    alias: signinL6e64VGCuOMeta?.alias || [],
    redirect: signinL6e64VGCuOMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: sourcex6sCxwFnjZMeta?.name ?? "source",
    path: sourcex6sCxwFnjZMeta?.path ?? "/source",
    meta: sourcex6sCxwFnjZMeta || {},
    alias: sourcex6sCxwFnjZMeta?.alias || [],
    redirect: sourcex6sCxwFnjZMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/source.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DzLnahi4mtMeta?.name ?? "tag-id",
    path: _91id_93DzLnahi4mtMeta?.path ?? "/tag/:id()",
    meta: _91id_93DzLnahi4mtMeta || {},
    alias: _91id_93DzLnahi4mtMeta?.alias || [],
    redirect: _91id_93DzLnahi4mtMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/tag/[id].vue").then(m => m.default || m)
  },
  {
    name: tagsoHMlEsLeToMeta?.name ?? "tags",
    path: tagsoHMlEsLeToMeta?.path ?? "/tags",
    meta: tagsoHMlEsLeToMeta || {},
    alias: tagsoHMlEsLeToMeta?.alias || [],
    redirect: tagsoHMlEsLeToMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: widgeteGIsjqkrWlMeta?.name ?? "widget",
    path: widgeteGIsjqkrWlMeta?.path ?? "/widget",
    meta: widgeteGIsjqkrWlMeta || {},
    alias: widgeteGIsjqkrWlMeta?.alias || [],
    redirect: widgeteGIsjqkrWlMeta?.redirect || undefined,
    component: () => import("/home/les/blob/pages/widget.vue").then(m => m.default || m)
  }
]